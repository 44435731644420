import sportsConst from "../common/sportsConst";
import localStorage from "./localStorage";
import managerConst from "../common/administrator/managerConst";
import inplayConst from "@/common/inplayConst";

export default {
    sampleserver: 0,
    LOW_BEDANG_FLAG: 1.4,
    isMaintaining: false,
    maintainingMessage: '',
    ischeckedagentcode: false,
    userinsertagentcode: '',
    ismobile: false,
    isLogin: false,
    isShowLoginPanel: false,
    isShowRegisterPanel: false,
    isShowAgentCodePanel: false,
    isShowTopbar: false,
    isLoading: false,
    isInplayLoading: false,
    isShowNotMemberCustomerePanel: false,
    iild: false,
    displayPostion: sportsConst.GAME_DISPLAYPOSITION_1x2,
    manager: {},
    userInfo: {
        nickname: '',
        cash: 0,
        userPoint: 0,
        casinocash: 0,
        slotcash: 0,
        onbetmoney: 0,
        rank: {rankName: '', betMaxLimitCash: 0, specBetMaxLimitCash: 0, winMaxLimitCash: 0, specWinMaxLimitCash: 0,betOneMaxLimitCash:0,inplayBetOneMaxLimitCash:0},
        group: {bettgameable: sportsConst.YES},
        myReferrer: {},
        referreCode: {},
        subordinateUsers: [],
        scoupon: 0,
        gcoupon: 0,
        dcoupon: 0,
    },
    uncheckedMessageCount: 0,
    commonConfig: {
        rankConfig: {betMaxLimitCash: 0, specBetMaxLimitCash: 0, winMaxLimitCash: 0, specWinMaxLimitCash: 0,betOneMaxLimitCash:0,inplayBetOneMaxLimitCash:0},
        bonusConfig: {},
        crossRuleconfig: {}
    },
    gameCount: {
        soccer: 0,
        baseball: 0,
        basketball: 0,
        volleyball: 0,
        hockey: 0,
        football: 0,
        esports: 0,
        alltypecount: 0,
    },
    sportsCartItems: [],
    inplayCartItems: [],
    sportsOriginal: [],
    sportsLive: [],
    minigames: {},
    currentSportsPage: sportsConst.GAME_DISPLAYPOSITION_1x2,
    selectedGameType: sportsConst.GAME_TYPE_NOT_SELECTED,
    selectedInplayGameType: inplayConst.SPORTS_ID_ALL,
    leagueList: [],
    selectedLeagueList: [],
    currentLeisureGameComp: -1,
    currentLeisureGameID: -1,
    currentBetHistoryIndex: 1,
    popups: [],
    mobileRightMenuStatus: false,
    isinplay: false,
    betcartshowable: false,

    m: {
        abc: 1,
        alarmOn: localStorage.get('alarm', managerConst.YES)
    },
    agent: {
        agentInfo: {
            account: '',
            nickname: '',
            bank: '',
            cash: 0,
            rechargeExchangeProfit: 0,
            sportsBetRollingProfit: 0,
            leisureBetRollingProfit: 0,
            casinoBetRollingProfit: 0,
            slotBetRollingProfit: 0,

        },
    },

}